import { Logger } from '_common/services';
import { BaseManipulator } from '../Common/Base';
import { JsonRange, SelectionFixer } from 'Editor/services/_Common/Selection';
import { NodeUtils } from 'Editor/services/DataManager';
import { InsertElementOperation, RemoveContentOperation } from '../../Operations';
import ReduxInterface from 'Editor/services/ReduxInterface';
import { ErrorCannotRemoveContent } from '../../Errors';

export class RemoveManipulator
  extends BaseManipulator
  implements Editor.Edition.IRemoveManipulator
{
  removeContent(
    ctx: Editor.Edition.ActionContext,
    opts: Editor.Edition.RemoveContentOptions = {},
  ): boolean {
    if (this.editionContext.debug) {
      Logger.trace('NormalManipulator removeContent', ctx);
    }
    if (!this.editionContext.DataManager) {
      return false;
    }

    let options: Editor.Edition.RemoveContentOptions = {
      selectionDirection: 'forward',
      useSelectedCells: false,
      ...opts,
    };

    // normalize text selection
    SelectionFixer.normalizeTextSelection(
      ctx.range,
      {
        suggestionMode: false,
        // forceWrapAsText: true, // WARN: avoid this prop here
        isDelete: opts.selectionDirection === 'forward',
        isBackspace: opts.selectionDirection === 'backward',
        forceTextAsWrap: true,
      },
      this.editionContext.DataManager,
    );

    // check whole selection is editable
    if (!JsonRange.isSelectionEditable(this.editionContext.DataManager, ctx.range)) {
      throw new ErrorCannotRemoveContent();
    }

    if (!opts.confirmDeleteCaption && this.validateCaptionsOnRange(ctx)) {
      ctx.avoidNextNonCollapsedAction = true;

      // trigger confirmation modal
      ReduxInterface.openDeleteCaptionConfirmationModal();

      return false;
    }

    this.removeTrackedParagraphMarkers(ctx, false, options.useSelectedCells);

    let startModel = this.editionContext.DataManager.nodes.getNodeModelById(ctx.range.start.b);
    if (!startModel) {
      return false;
    }

    // get base level ranges
    let rangesToRemove = JsonRange.splitRangeByTypes(
      this.editionContext.DataManager,
      ctx.range,
      [...NodeUtils.BLOCK_TEXT_TYPES, ...NodeUtils.BLOCK_NON_TEXT_TYPES],
      {
        onlyContainerLevel: true,
        useSelectedCells: options.useSelectedCells,
      },
    );

    let resultPath: Editor.Selection.Position | undefined;

    let removeBlockOps: Editor.Edition.IOperationBuilder[] = [];

    let startBaseModelId: string | undefined;
    let startBlockInfo: Editor.Data.Node.DataPathInfo | undefined;

    for (let i = 0; i < rangesToRemove.length; i++) {
      const range = rangesToRemove[i].range;

      const baseModel = this.editionContext.DataManager.nodes.getNodeModelById(range.start.b);

      const baseData = baseModel?.selectedData();
      if (!baseModel || !baseData) {
        continue;
      }
      let closestContainer = NodeUtils.closestOfTypeByPath(
        baseData,
        range.getCommonAncestorPath(),
        NodeUtils.MULTI_BLOCK_CONTAINER_TYPES,
      );
      let closestBlock: Editor.Data.Node.DataPathInfo | null = null;
      if (closestContainer) {
        closestBlock = NodeUtils.closestOfTypeByPath(
          closestContainer.data,
          range.getCommonAncestorPath().slice(closestContainer.path.length),
          [...NodeUtils.BLOCK_TEXT_TYPES, ...NodeUtils.BLOCK_NON_TEXT_TYPES],
        );
        if (!closestBlock) {
          closestBlock = NodeUtils.closestOfTypeByPath(
            closestContainer.data,
            range.getCommonAncestorPath().slice(closestContainer.path.length),
            ['tracked-insert', 'tracked-delete'],
          );
        }

        if (closestBlock) {
          closestBlock.path = [...closestContainer.path, ...closestBlock.path];
        }
      } else {
        closestBlock = NodeUtils.closestOfTypeByPath(baseData, range.getCommonAncestorPath(), [
          ...NodeUtils.BLOCK_TEXT_TYPES,
          ...NodeUtils.BLOCK_NON_TEXT_TYPES,
        ]);
        if (!closestBlock) {
          closestBlock = NodeUtils.closestOfTypeByPath(baseData, range.getCommonAncestorPath(), [
            'tracked-insert',
            'tracked-delete',
          ]);
        }
      }

      if (closestBlock) {
        if (i === 0) {
          startBaseModelId = baseModel.id;
          startBlockInfo = closestBlock;
          // FIRST ELEMENT
          // TODO: check for suggestion paragraph markers

          if (NodeUtils.isBlockTextData(closestBlock.data)) {
            if (!range.isCollapsed()) {
              // TEXT BLOCKS
              let removeOp = new RemoveContentOperation(baseModel, range.start.p, range.end.p, {
                ...opts,
                pathFix: 'AFTER', // should be after to avoid scenarios where content is wrongfully inserted inside format elements;
              });
              removeOp.apply();
              let adjustedPath = removeOp.getAdjustedPath();

              if (adjustedPath) {
                resultPath = {
                  b: baseModel.id,
                  p: adjustedPath,
                };
              }
            } else {
              // don't have ops on this block but we need the path
              const childLength = closestBlock.data.childNodes?.length;

              if (childLength && closestBlock.data.id) {
                let lastElement = closestBlock.data.childNodes?.[childLength - 1];

                if (NodeUtils.isTextData(lastElement)) {
                  resultPath = {
                    b: baseModel.id,
                    p: [
                      ...closestBlock.path,
                      'childNodes',
                      childLength - 1,
                      'content',
                      lastElement.content.length,
                    ],
                  };
                } else {
                  // don't have ops on this block but we need the path
                  resultPath = {
                    b: baseModel.id,
                    p: ctx.range.start.p,
                  };
                }
              }
            }
          } else {
            // NON TEXT BLOCKS
            let removeOp = this.getRemoveBlockOperation(
              baseModel,
              closestBlock.data,
              closestBlock.path,
            );
            if (removeOp) {
              removeBlockOps.push(removeOp);
              resultPath = removeOp.getPosOpPosition();
            }
          }
        } else if (i === rangesToRemove.length - 1) {
          // LAST ELEMENT
          if (
            startBlockInfo &&
            NodeUtils.canBlocksBeMerged(startBlockInfo.data, closestBlock.data) &&
            NodeUtils.isBlockTextData(closestBlock.data)
          ) {
            const textElementData = closestBlock.data;

            if (!resultPath && !startBlockInfo.data.childNodes?.length && startBaseModelId) {
              resultPath = {
                b: startBaseModelId,
                p: [...startBlockInfo.path, 'childNodes', 0],
              };
            }

            // join content
            if (!options.useSelectedCells) {
              // let startOffset = startBlockInfo.data.childNodes?.length || 0;
              let pathToMerge: Editor.Selection.Path | undefined;
              if (!resultPath) {
                let adjustedPath: Editor.Selection.Path = [
                  ...startBlockInfo.path,
                  'childNodes',
                  startBlockInfo.data.childNodes?.length || 0,
                ];

                if (adjustedPath) {
                  resultPath = {
                    b: baseModel.id,
                    p: adjustedPath,
                  };
                }
              }
              pathToMerge = resultPath?.p;

              let cloneStartPath: Editor.Selection.Path = range.end.p.slice(
                closestBlock.path.length,
              );
              let cloneEndPath: Editor.Selection.Path = [
                'childNodes',
                textElementData.childNodes?.length || 0,
              ];

              const clonedNodes = NodeUtils.cloneData(
                textElementData,
                cloneStartPath,
                cloneEndPath,
              );

              let preOpPosition: Editor.Selection.Position | undefined;
              for (let i = 0; i < clonedNodes.length; i++) {
                if (pathToMerge) {
                  let insertOp: InsertElementOperation = new InsertElementOperation(
                    startModel,
                    pathToMerge,
                    clonedNodes[i],
                  );
                  if (insertOp.hasOpsToApply()) {
                    insertOp.apply();
                    pathToMerge = insertOp.getAdjustedPath();

                    if (!preOpPosition) {
                      preOpPosition = insertOp.getPreOpPosition();
                    }
                  }
                }
              }

              if (preOpPosition) {
                resultPath = preOpPosition;
              }
            }

            let removeOp = this.getRemoveBlockOperation(
              baseModel,
              closestBlock.data,
              closestBlock.path,
            );
            if (removeOp) {
              removeBlockOps.push(removeOp);
            }
          } else {
            let removeOp = this.getRemoveBlockOperation(
              baseModel,
              closestBlock.data,
              closestBlock.path,
            );
            if (removeOp) {
              removeBlockOps.push(removeOp);
            }
          }
        } else {
          // MIDDLE ELEMENTS
          let removeOp = this.getRemoveBlockOperation(
            baseModel,
            closestBlock.data,
            closestBlock.path,
          );
          if (removeOp) {
            removeBlockOps.push(removeOp);
            // resultPath is not supposed to be update here
            // resultPath = removeOp.getPosOpPosition();
          }
        }
      }

      if (resultPath) {
        // update suggestion content if any
        ctx.addSuggestionLocation(baseModel.id, resultPath.p);
      }
    }

    for (let r = removeBlockOps.length - 1; r >= 0; r--) {
      if (removeBlockOps[r]) {
        removeBlockOps[r].apply();
      }
    }

    if (resultPath) {
      const resultModel = this.editionContext.DataManager.nodes.getNodeModelById(resultPath?.b);
      const resultData = resultModel?.selectedData();
      if (resultData) {
        let path = SelectionFixer.normalizeJsonPath(resultData, resultPath.p);
        if (path) {
          resultPath.p = path;
        }
      }
      ctx.range.updateRangePositions(resultPath);
    } else {
      ctx.range.collapse(true);
    }

    return true;
  }

  removeBlock(
    ctx: Editor.Edition.ActionContext,
    opts: Editor.Edition.RemoveContentOptions = {},
  ): boolean {
    let options: Editor.Edition.RemoveContentOptions = {
      selectionDirection: 'forward',
      useSelectedCells: false,
      ...opts,
    };

    if (this.editionContext.debug) {
      Logger.trace('NormalManipulator removeBlock', ctx);
    }
    if (!this.editionContext.DataManager) {
      return false;
    }

    // check whole selection is editable
    if (!JsonRange.isSelectionEditable(this.editionContext.DataManager, ctx.range)) {
      throw new ErrorCannotRemoveContent();
    }

    this.removeTrackedParagraphMarkers(ctx, false, opts.useSelectedCells);

    // get base level ranges
    let rangesToRemove = JsonRange.splitRangeByTypes(
      this.editionContext.DataManager,
      ctx.range,
      [...NodeUtils.BLOCK_TEXT_TYPES, ...NodeUtils.BLOCK_NON_TEXT_TYPES],
      {
        onlyContainerLevel: true,
        useSelectedCells: options.useSelectedCells,
      },
    );

    let resultPath: Editor.Selection.Position | undefined;

    let removeBlockOps: Editor.Edition.IOperationBuilder[] = [];

    for (let i = 0; i < rangesToRemove.length; i++) {
      const range = rangesToRemove[i].range;

      const baseModel = this.editionContext.DataManager.nodes.getNodeModelById(range.start.b);

      const baseData = baseModel?.selectedData();
      if (!baseModel || !baseData) {
        continue;
      }

      const closestBlock = NodeUtils.closestOfTypeByPath(baseData, range.getCommonAncestorPath(), [
        ...NodeUtils.BLOCK_TEXT_TYPES,
        ...NodeUtils.BLOCK_NON_TEXT_TYPES,
      ]);

      if (closestBlock) {
        let removeOp = this.getRemoveBlockOperation(
          baseModel,
          closestBlock.data,
          closestBlock.path,
          { blockSelectionFix: 'NEXT' },
        );
        if (removeOp) {
          removeBlockOps.push(removeOp);
          resultPath = removeOp.getPosOpPosition();
        }
      }

      if (resultPath) {
        // update suggestion content if any
        ctx.addSuggestionLocation(baseModel.id, resultPath.p);
      }
    }

    for (let r = removeBlockOps.length - 1; r >= 0; r--) {
      if (removeBlockOps[r]) {
        removeBlockOps[r].apply();
      }
    }

    if (resultPath) {
      ctx.range.updateRangePositions(resultPath);
    } else {
      ctx.range.collapse(true);
    }

    return true;
  }
}
